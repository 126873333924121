<template>
  <v-container fluid>
    <v-layout row>
      <v-spacer />

      <v-flex xs4>
        <v-layout column class="opt-menu">
          <v-flex>
            <v-flex xs8>
              <v-card flat class="opt-item">
                <v-card-title>
                  <v-flex><h3>Reservations</h3></v-flex>
                </v-card-title>

                <v-card-text>
                  <v-flex>
                    <v-text-field
                      :id="`admin-form-text-field-activate-reservation`"
                      v-model="reservationIdActivate"
                      label="Reservation Id"
                    />
                  </v-flex>
                  <v-flex>Reservation Status: {{ reservationStatus }}</v-flex>
                </v-card-text>
                <v-card-actions class="place-end">
                  <v-btn
                    :id="`admin-form-button-deactivate-reservation`"
                    solo
                    flat
                    outline
                    color="grey"
                    :loading="reservationApiCallStatus"
                    @click="deactivateReservation"
                  >
                    Deactivate Reservation
                  </v-btn>
                  <v-btn
                    :id="`admin-form-button-activate-reservation`"
                    solo
                    flat
                    outline
                    :loading="reservationApiCallStatus"
                    color="primary"
                    @click="activateReservation"
                  >
                    Activate Reservation
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-flex>

            <v-spacer class="bottom-space" />

            <v-flex xs8>
              <v-card flat class="opt-item">
                <v-card-title>
                  <v-flex><h3>Update Reservation Status</h3></v-flex>
                </v-card-title>

                <v-card-text>
                  <v-flex>
                    <v-text-field
                      :id="`admin-form-text-field-update-reservation`"
                      v-model="reservationIdToUpdate"
                      label="Reservation Id"
                    />
                  </v-flex>

                  <div>
                    <v-flex xs12 d-flex>
                      <CRSelect
                        :id="`admin-form-select-update-reservation-status`"
                        v-model="reservationStatus"
                        :items="reservationStatusMap"
                        item-value="value"
                        label="Select Here"
                        placeholder="Select status"
                      />
                    </v-flex>
                  </div>

                  <v-card-text>
                    {{ updateReservationStatusMessage }}
                  </v-card-text>
                </v-card-text>
                <v-card-actions class="place-end">
                  <v-btn
                    :id="`admin-form-button-update-reservation-status`"
                    solo
                    flat
                    outline
                    color="primary"
                    :loading="updateReservationByApiCallStatus"
                    @click="updateReservationStatus"
                  >
                    Update Reserv. Status
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-flex>

            <v-spacer class="bottom-space" />

            <v-flex xs8>
              <v-card flat class="opt-item">
                <v-card-title>
                  <v-flex><h3>Duplicate Quote En Masse</h3></v-flex>
                </v-card-title>

                <v-card-text>
                  <v-flex>
                    <v-text-field
                      :id="`admin-form-text-field-duplicate-quote-id`"
                      v-model.number="quoteIdToDuplicate"
                      label="Quote Id"
                      type="Number"
                    />
                  </v-flex>

                  <v-flex>
                    <v-text-field
                      :id="`admin-form-text-field-duplicate-quote-quantity`"
                      v-model.number="quantity"
                      label="Quantity"
                      type="Number"
                    />
                  </v-flex>

                  <v-card-text>
                    {{ duplicateQuoteEnMasseMessage }}
                  </v-card-text>
                </v-card-text>
                <v-card-actions class="place-end">
                  <v-btn
                    :id="`admin-form-button-duplicate-quote`"
                    solo
                    flat
                    outline
                    color="primary"
                    :loading="duplicateQuoteEnMasseByApiCallStatus"
                    @click="duplicateQuoteEnMasse"
                  >
                    Duplicate Quote
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-flex>

            <v-spacer class="bottom-space" />

            <v-flex xs8>
              <v-card flat class="opt-item">
                <v-card-title>
                  <v-flex><h3>Convert Quotes En Masse</h3></v-flex>
                </v-card-title>

                <v-card-text>
                  <v-flex>
                    <v-text-field
                      :id="`admin-form-text-field-contract-id`"
                      v-model.number="contractId"
                      label="Contract Id"
                      type="Number"
                    />
                  </v-flex>

                  <v-card-text>
                    {{ convertQuotesEnMasseMessage }}
                  </v-card-text>
                </v-card-text>
                <v-card-actions class="place-end">
                  <v-btn
                    :id="`admin-form-button-convert-quotes-en-masse`"
                    solo
                    flat
                    outline
                    color="primary"
                    :loading="convertQuotesEnMasseByApiCallStatus"
                    @click="convertQuotesEnMasse"
                  >
                    Convert Quotes
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-flex>

            <v-spacer class="bottom-space" />

            <v-flex xs8>
              <v-card flat class="opt-item">
                <v-card-title>
                  <v-flex><h3>Create Referrals En Masse</h3></v-flex>
                </v-card-title>

                <v-card-text>
                  <v-flex>
                    <v-text-field
                      :id="`admin-form-text-field-create-referrals-contract-id`"
                      v-model.number="contractId"
                      label="Contract Id"
                      type="Number"
                    />
                  </v-flex>

                  <v-flex>
                    <v-text-field
                      :id="`admin-form-text-field-create-referrals-company-id`"
                      v-model.number="companyId"
                      label="Company Id"
                      type="Number"
                    />
                  </v-flex>

                  <v-flex>
                    <v-text-field
                      :id="`admin-form-text-field-create-referrals-amount`"
                      v-model.number="amount"
                      label="Amount"
                      type="Number"
                    />
                  </v-flex>

                  <v-card-text>
                    {{ createReferralsEnMasseMessage }}
                  </v-card-text>
                </v-card-text>
                <v-card-actions class="place-end">
                  <v-btn
                    :id="`admin-form-button-create-referrals`"
                    solo
                    flat
                    outline
                    color="primary"
                    :loading="createReferralsEnMasseByApiCallStatus"
                    @click="createReferralsEnMasse"
                  >
                    Create Referrals
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-flex>

            <v-spacer class="bottom-space" />

            <v-flex xs8>
              <v-card flat class="opt-item">
                <v-card-title>
                  <v-flex><h3>Accept Referrals En Masse</h3></v-flex>
                </v-card-title>

                <v-card-text>
                  <v-flex>
                    <v-text-field
                      :id="`admin-form-text-field-accept-referrals-contract-id`"
                      v-model.number="contractId"
                      label="Contract Id"
                      type="Number"
                    />
                  </v-flex>

                  <v-card-text>
                    {{ acceptReferralsEnMasseMessage }}
                  </v-card-text>
                </v-card-text>
                <v-card-actions class="place-end">
                  <v-btn
                    :id="`admin-form-button-accept-referrals`"
                    solo
                    flat
                    outline
                    color="primary"
                    :loading="acceptReferralsEnMasseByApiCallStatus"
                    @click="acceptReferralsEnMasse"
                  >
                    Accept Referrals
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-flex>
          </v-flex>
        </v-layout>
      </v-flex>

      <v-spacer />

      <v-flex xs4>
        <v-layout column class="opt-select">
          <v-flex>
            <v-flex xs8>
              <v-card flat class="opt-item">
                <v-card-title>
                  <v-flex><h3>Update Reservation BookedBy Id</h3></v-flex>
                </v-card-title>

                <v-card-text>
                  <v-flex>
                    <v-text-field
                      :id="`admin-form-text-field-update-bookedBy-reservation-id`"
                      v-model="bookedByReservationId"
                      label="Reservation Id"
                    />
                  </v-flex>
                  <v-flex>
                    <v-text-field
                      :id="`admin-form-text-field-bookedBy-id`"
                      v-model="bookedById"
                      label="BookedBy Id"
                    />
                  </v-flex>
                  <v-card-text>{{ bookedByStatus }}</v-card-text>
                </v-card-text>
                <v-card-actions class="place-end">
                  <v-btn
                    solo
                    flat
                    outline
                    color="primary"
                    :loading="bookedByApiCallStatus"
                    @click="updateReservationBookedBy"
                  >
                    Update Reservation BookedBy Id
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-flex>

            <v-spacer class="bottom-space" />

            <v-flex xs8>
              <v-card flat class="opt-item">
                <v-card-title>
                  <v-flex><h3>Update Quote CreatedBy Id</h3></v-flex>
                </v-card-title>

                <v-card-text>
                  <v-flex>
                    <v-text-field
                      :id="`admin-form-text-field-quote-id-to-update`"
                      v-model="quoteIdToUpdate"
                      label="Quote Id"
                    />
                  </v-flex>
                  <v-flex>
                    <v-text-field
                      :id="`admin-form-text-field-quote-created-by`"
                      v-model="quoteCreatedById"
                      label="CreatedBy Id"
                    />
                  </v-flex>
                  <v-card-text>{{ updateQuoteCreatedByStatus }}</v-card-text>
                </v-card-text>
                <v-card-actions class="place-end">
                  <v-btn
                    :id="`admin-form-button-update-quote-createdBy`"
                    solo
                    flat
                    outline
                    color="primary"
                    :loading="updateQuoteCreatedByApiCallStatus"
                    @click="updateQuoteCreatedBy"
                  >
                    Update Quote CreatedBy
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-flex>

            <v-spacer class="bottom-space" />

            <v-flex xs8>
              <v-card flat class="opt-item">
                <v-card-title>
                  <v-flex><h3>Update Reservation Customer Id</h3></v-flex>
                </v-card-title>

                <v-card-text>
                  <v-flex>
                    <v-text-field
                      :id="`admin-form-text-field-reservation-id-to-update`"
                      v-model="reservationIdToUpdate"
                      label="Reservation ID"
                    />
                  </v-flex>
                  <v-flex>
                    <v-text-field
                      :id="`admin-form-text-field-reservation-customer-id-to-update`"
                      v-model="reservationCustomerIdToUpdate"
                      label="Customer ID"
                    />
                  </v-flex>
                  <v-card-text>
                    {{ updateReservationCustomerIdStatus }}
                  </v-card-text>
                </v-card-text>
                <v-card-actions class="place-end">
                  <v-btn
                    :id="`admin-form-button-update-reservation-customer-id`"
                    solo
                    flat
                    outline
                    color="primary"
                    :loading="updateReservationCustomerIdApiCallStatus"
                    @click="updateReservationCustomerId"
                  >
                    Update Reservation Customer Id
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-flex>

            <v-spacer class="bottom-space" />

            <v-flex xs8>
              <v-card flat class="opt-item">
                <v-card-title>
                  <v-flex><h3>Update Converted Quote Customer Id</h3></v-flex>
                </v-card-title>

                <v-card-text>
                  <v-flex>
                    <v-text-field
                      :id="`admin-form-text-field-converted-quote-id-to-update`"
                      v-model="convertedQuoteIdToUpdate"
                      label="Converted Quote ID"
                    />
                  </v-flex>
                  <v-flex>
                    <v-text-field
                      :id="`admin-form-text-field-converted-quote-customer-id-to-update`"
                      v-model="convertedQuoteCustomerIdToUpdate"
                      label="Customer ID"
                    />
                  </v-flex>
                  <v-card-text>
                    {{ updateConvertedQuoteCustomerIdStatus }}
                  </v-card-text>
                </v-card-text>
                <v-card-actions class="place-end">
                  <v-btn
                    :id="`admin-form-button-update-converted-quote-customer-id`"
                    solo
                    flat
                    outline
                    color="primary"
                    :loading="updateConvertedQuoteCustomerIdApiCallStatus"
                    @click="updateConvertedQuoteCustomerId"
                  >
                    Update Converted Quote Customer Id
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-flex>

            <v-spacer class="bottom-space" />

            <v-flex xs8>
              <v-card flat class="opt-item">
                <v-card-title>
                  <v-flex><h3>Set First And Last Stop</h3></v-flex>
                </v-card-title>

                <v-card-text>
                  <v-flex>
                    <v-text-field
                      :id="`admin-form-text-field-first-quote-id`"
                      v-model.number="firstQuoteId"
                      label="First Quote Id"
                      type="Number"
                    />
                  </v-flex>

                  <v-flex>
                    <v-text-field
                      :id="`admin-form-text-field-last-quote-id`"
                      v-model.number="lastQuoteId"
                      label="Last Quote Id"
                      type="Number"
                    />
                  </v-flex>

                  <v-card-text>
                    {{ setFirstAndLastStopMessage }}
                  </v-card-text>
                </v-card-text>
                <v-card-actions class="place-end">
                  <v-btn
                    :id="`admin-form-button-set-first-last-stop`"
                    solo
                    flat
                    outline
                    color="primary"
                    :loading="setFirstAndLastStopByApiCallStatus"
                    @click="setFirstAndLastStop"
                  >
                    Set First and Last Stop
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-flex>

            <v-spacer class="bottom-space" />

            <v-flex xs8>
              <v-card flat class="opt-item">
                <v-card-title>
                  <v-flex><h3>Set Referral Status</h3></v-flex>
                </v-card-title>

                <v-card-text>
                  <v-flex>
                    <v-text-field
                      :id="`admin-form-text-field-first-reservation`"
                      v-model="firstReservationId"
                      label="First Reservation Id"
                    />
                  </v-flex>

                  <v-flex>
                    <v-text-field
                      :id="`admin-form-form-text-field-last-reservation`"
                      v-model="lastReservationId"
                      label="Last Reservation Id"
                    />
                  </v-flex>

                  <v-card-text>
                    {{ setReferralStatusMessage }}
                  </v-card-text>
                </v-card-text>
                <v-card-actions class="place-end">
                  <v-btn
                    :id="`admin-form-button-set-referral-status`"
                    solo
                    flat
                    outline
                    color="primary"
                    :loading="setReferralStatusByApiCallStatus"
                    @click="setReferralStatus"
                  >
                    Set Referral Status
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-flex>
          </v-flex>
        </v-layout>
      </v-flex>

      <v-spacer xs8 />
    </v-layout>
  </v-container>
</template>

<script>
import admin from '@/services/admin'

export default {
  data() {
    return {
      reservationIdActivate: null,
      reservationStatus: null,
      reservationApiCallStatus: false,
      quoteCreatedById: null,
      bookedById: null,
      bookedByReservationId: null,
      bookedByStatus: false,
      bookedByApiCallStatus: false,
      updateQuoteCreatedByStatus: '',
      updateQuoteCreatedByApiCallStatus: false,
      updateReservationStatusMessage: '',
      updateReservationByApiCallStatus: false,
      updateConvertedQuoteCustomerIdApiCallStatus: false,
      updateConvertedQuoteCustomerIdStatus: '',
      updateReservationCustomerIdApiCallStatus: false,
      updateReservationCustomerIdStatus: '',
      duplicateQuoteEnMasseByApiCallStatus: false,
      duplicateQuoteEnMasseMessage: '',
      convertQuotesEnMasseByApiCallStatus: false,
      convertQuotesEnMasseMessage: '',
      setFirstAndLastStopByApiCallStatus: false,
      setFirstAndLastStopMessage: '',
      setReferralStatusByApiCallStatus: false,
      setReferralStatusMessage: '',
      createReferralsEnMasseByApiCallStatus: false,
      createReferralsEnMasseMessage: '',
      acceptReferralsEnMasseByApiCallStatus: false,
      acceptReferralsEnMasseMessage: '',
      reservationIdToUpdate: null,
      reservationStatusMap: [
        { text: 'Upcoming', value: 'upcoming' },
        { text: 'Started', value: 'started' },
        { text: 'Finished', value: 'finished' },
        { text: 'Cancelled', value: 'cancelled' },
        { text: 'Hold', value: 'hold' },
      ],
    }
  },
  methods: {
    async activateReservation() {
      this.reservationApiCallStatus = true
      try {
        await admin
          .activateReservation(this.reservationIdActivate)
          .then((d) => d.data)
        this.reservationStatus =
          'Successfully Activated ' + this.reservationIdActivate
      } catch (e) {
        this.reservationApiCallStatus = false
        this.reservationStatus = 'Failed to update due to, ' + e
        return
      }
      this.reservationApiCallStatus = false
    },
    async deactivateReservation() {
      this.reservationApiCallStatus = true
      try {
        await admin
          .deactivateReservation(this.reservationIdActivate)
          .then((d) => d.data)
        this.reservationStatus =
          'Successfully deactivated ' + this.reservationIdActivate
      } catch (e) {
        this.reservationApiCallStatus = false
        this.reservationStatus = 'Failed to update due to, ' + e
        return
      }
      this.reservationApiCallStatus = false
    },
    async updateReservationBookedBy() {
      this.bookedByApiCallStatus = true
      try {
        await admin
          .updateReservationBookedBy({
            userId: this.bookedById,
            managedId: this.bookedByReservationId,
          })
          .then((d) => d.data)
        this.bookedByStatus =
          'Updated ' +
          this.bookedByReservationId +
          ' with bookedById of ' +
          this.bookedById
      } catch (e) {
        this.bookedByApiCallStatus = false
        this.bookedByStatus = 'Failed to update due to, ' + e
        return
      }
      this.bookedByApiCallStatus = false
    },
    async updateQuoteCreatedBy() {
      this.updateQuoteCreatedByApiCallStatus = true
      try {
        await admin
          .updateQuoteCreatedBy({
            quoteId: this.quoteIdToUpdate,
            userId: this.quoteCreatedById,
          })
          .then((d) => d.data)
        this.updateQuoteCreatedByStatus = `Updated ${this.quoteIdToUpdate} with createdById of ${this.quoteCreatedById}`
      } catch (e) {
        this.updateQuoteCreatedByApiCallStatus = false
        this.updateQuoteCreatedByStatus =
          'Failed to update likely due to bad ids, ' + e
        return
      }
      this.updateQuoteCreatedByApiCallStatus = false
    },
    async updateReservationCustomerId() {
      this.updateReservationCustomerIdApiCallStatus = true
      try {
        await admin
          .updateReservationCustomerId({
            managedId: this.reservationIdToUpdate,
            customerId: this.reservationCustomerIdToUpdate,
          })
          .then(
            (d) => (this.updateReservationCustomerIdStatus = d.data.message)
          )
      } catch (e) {
        this.updateReservationCustomerIdStatus = e
      }
      this.updateReservationCustomerIdApiCallStatus = false
    },
    async updateConvertedQuoteCustomerId() {
      this.updateConvertedQuoteCustomerIdApiCallStatus = true
      try {
        await admin
          .updateConvertedQuoteCustomerId({
            quoteId: this.convertedQuoteIdToUpdate,
            customerId: this.convertedQuoteCustomerIdToUpdate,
          })
          .then(
            (d) => (this.updateConvertedQuoteCustomerIdStatus = d.data.message)
          )
      } catch (e) {
        this.updateConvertedQuoteCustomerIdStatus = e
      }
      this.updateConvertedQuoteCustomerIdApiCallStatus = false
    },
    async updateReservationStatus() {
      this.updateReservationCreatedByApiCallStatus = true

      if (!this.reservationStatus || !this.reservationIdToUpdate) {
        this.updateReservationByApiCallStatus = false
        this.updateReservationStatusMessage = 'Missing Fields'
        return
      }

      try {
        await admin
          .updateReservationStatus({
            managedId: this.reservationIdToUpdate,
            reservationStatus: this.reservationStatus,
          })
          .then((d) => d.data)
        this.updateReservationStatusMessage = `Updated ${this.reservationIdToUpdate} status to ${this.reservationStatus}`
      } catch (e) {
        this.updateReservationByApiCallStatus = false
        this.updateReservationStatusMessage =
          'Failed to update likely due to bad ids, ' + e
        return
      }

      this.updateReservationCreatedByApiCallStatus = false
    },
    async duplicateQuoteEnMasse() {
      this.duplicateQuoteEnMasseByApiCallStatus = true
      try {
        await admin
          .duplicateQuoteEnMasse({
            quoteId: this.quoteIdToDuplicate,
            quantity: this.quantity,
          })
          .then((d) => d.data)
        this.duplicateQuoteEnMasseByStatus = `Duplicated Quote ${this.quoteIdToDuplicate}. Quantity: ${this.quantity}`
      } catch (e) {
        this.duplicateQuoteEnMasseByApiCallStatus = false
        this.duplicateQuoteEnMasseByStatus =
          'Failed to duplicate quote. likely due to bad id, ' + e
        return
      }
      this.duplicateQuoteEnMasseByApiCallStatus = false
    },
    async convertQuotesEnMasse() {
      this.convertQuotesEnMasseByApiCallStatus = true
      try {
        await admin
          .convertQuotesEnMasse({
            contractId: this.contractId,
          })
          .then((d) => d.data)
        this.convertQuotesEnMasseByStatus = `Converted Quotes for Contract ${this.contractId}.`
      } catch (e) {
        this.convertQuotesEnMasseByApiCallStatus = false
        this.convertQuotesEnMasseByStatus =
          'Failed to convert quotes. likely due to bad contract id, ' + e
        return
      }
      this.convertQuotesEnMasseByApiCallStatus = false
    },
    async createReferralsEnMasse() {
      this.createReferralsEnMasseByApiCallStatus = true
      try {
        await admin
          .createReferralsEnMasse({
            contractId: this.contractId,
            companyId: this.companyId,
            amount: this.amount,
          })
          .then((d) => d.data)
        this.createReferralsEnMasseByStatus = `Created Referrals for Contract ${this.contractId}.`
      } catch (e) {
        this.createReferralsEnMasseByApiCallStatus = false
        this.createReferralsEnMasseByStatus =
          'Failed to create referrals. likely due to bad contract id, ' + e
        return
      }
      this.createReferralsEnMasseByApiCallStatus = false
    },
    async acceptReferralsEnMasse() {
      this.acceptReferralsEnMasseByApiCallStatus = true
      try {
        await admin
          .acceptReferralsEnMasse({
            contractId: this.contractId,
          })
          .then((d) => d.data)
        this.acceptReferralsEnMasseByStatus = `Acceptd Referrals for Contract ${this.contractId}.`
      } catch (e) {
        this.acceptReferralsEnMasseByApiCallStatus = false
        this.acceptReferralsEnMasseByStatus =
          'Failed to accept referrals. likely due to bad contract id, ' + e
        return
      }
      this.createReferralsEnMasseByApiCallStatus = false
    },
    async setFirstAndLastStop() {
      this.setFirstAndLastStopByApiCallStatus = true
      try {
        await admin
          .setFirstAndLastStop({
            firstQuoteId: this.firstQuoteId,
            lastQuoteId: this.lastQuoteId,
          })
          .then((d) => d.data)
        this.setFirstAndLastStopByStatus = `Set First and Last Stops for quotes from ${this.firstQuoteId} to ${this.lastQuoteId}`
      } catch (e) {
        this.setFirstAndLastStopByApiCallStatus = false
        this.setFirstAndLastStopByStatus =
          'Failed to set first and last stop. likely due to bad quote ids, ' + e
        return
      }
      this.setFirstAndLastStopByApiCallStatus = false
    },
    async setReferralStatus() {
      this.setReferralStatusByApiCallStatus = true
      try {
        await admin
          .setReferralStatus({
            firstManagedId: this.firstReservationId,
            lastManagedId: this.lastReservationId,
          })
          .then((d) => d.data)
        this.setReferralStatusByStatus = `Set Referral Status for reservations from ${this.firstReservationId} to ${this.lastReservationId}`
      } catch (e) {
        this.setReferralStatusByApiCallStatus = false
        this.setReferralStatusByStatus =
          'Failed to set referral status. likely due to bad reservation ids, ' +
          e
        return
      }
      this.setReferralStatusByApiCallStatus = false
    },
  },
}
</script>

<style lang="scss" scoped>
.bottom-space {
  margin-bottom: 15px;
}

.place-end {
  flex-wrap: wrap;
  justify-content: flex-end;
}
</style>
